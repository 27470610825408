import { serviceTypes } from '@umahealth/entities'
import Cookies from 'js-cookie'
import { PostHog } from 'posthog-js/react'

export const checkUtmParams = (posthog: PostHog, uid: string, service: serviceTypes, assignationId: string) => {
	const utmSource = Cookies.get('utm_source')
	const utmMedium = Cookies.get('utm_medium')
	const utmCampaign = Cookies.get('utm_campaign')
	if (utmSource && utmMedium && utmCampaign) {
		posthog.capture('new_appointment', {
			uid,
			service,
			assignationId,
			utmSource,
			utmMedium,
			utmCampaign,
		})
	}
}